<template>
  <div class="mar-top-60">
    <div v-nk-loading="loading">
      <div class="header-bg-wrap">
        <div class="page-title adaptive-width">{{ $t('settled.instructions') }}</div>
      </div>
      <div class="seller-guide">
        <div class="adaptive-width seller-guid-item seller-flow page-width">
          <div class="title">{{ $t('settled.process') }}</div>
          <ul class="seller-flow-list">
            <li class="seller-flow-item" v-for="(item, index) in processData" :key="index">
              <div class="seller-flow-num">{{ item.sequence }}</div>
              <div class="seller-flow-content">
                <div class="seller-flow-title">{{ item[getLangType('processTitle')] }}</div>
                <div class="seller-flow-desc" v-html="item[getLangType('processContentOne')]"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="seller-guid-item seller-policy">
          <div class="adaptive-width seller-policy-content page-width">
            <div class="title">{{ $t('settled.investmentPolicy') }}</div>
            <ul class="seller-policy-list">
              <li class="seller-policy-item" v-for="(item, index) in policyData" :key="index">
                <div class="seller-policy-title">{{ item[getLangType('policyTitle')] }}</div>
                <div class="seller-policy-sub-title">{{ item[getLangType('seller')] }}</div>
                <div class="seller-policy-desc">
                  <p class="seller-policy-p" v-html="item[getLangType('policyContentOne')]"></p>
                </div>
              </li>
            </ul>
            <!-- <div class="bg" style="flex: 1">
              <img src="@/assets/image/home/policy_bg.png" style="width: 100%" alt="图" />
            </div>-->
          </div>
        </div>
        <div class="adaptive-width seller-guid-item seller-notice page-width">
          <div class="title">{{ $t('settled.admissionNotice') }}</div>
          <div class="seller-notice-content" v-for="(item, index) in noticeData" :key="index">
            <p class="seller-policy-p" v-html="item[getLangType('noticeContentOne')]"></p>
            <p class="seller-notice-content-tip">{{ item[getLangType('noticeTip')] }}</p>
          </div>
        </div>
        <div
          v-if="recommendationData"
          class="adaptive-width seller-guid-item seller-banner page-width"
        >
          <div class="banner-title">{{ $t('settled.partnerReferrals') }}</div>
          <div class="seller-banner-content banner-slider">
            <div class="items" v-for="(item, index) in recommendationData" :key="index">
              <img v-real-img="item.recommendationImg" alt src class="item-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-wrap">
        <button
          type="button"
          class="bottom-wrap-button mall-button mall-button--primary mall-button--normal"
          @click="applicationForm()"
        >
          <span>填写入驻申请</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      processData: [],
      policyData: [],
      noticeData: [],
      recommendationData: [],
      loading: false,
    }
  },
  mounted() {
    this.getValueKey()
  },
  computed: {
    getLangType() {
      return function (key) {
        switch (this.$i18n.locale) {
          case 'en_US':
            return 'en_' + key
          case 'zh_CN':
            return key
          default:
            return key
        }
      }
    },
  },
  methods: {
    getValueKey() {
      const data = {
        type: 1,
      }
      this.loading = true
      this.$api
        .getProductList(data)
        .then((res) => {
          console.log('res', res)
          this.processData = res.data.entryProcess
          this.policyData = res.data.investmentPolicy
          this.noticeData = res.data.notice
          this.recommendationData = res.data.recommendation
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    entryRecords() {
      this.$store.state.token
        ? this.$router.push({ path: '/signIn/settled/applicationRecord' })
        : this.$router.push({ path: '/signIn/settled/choice' })
    },
    applicationForm() {
      this.$store.state.token
        ? this.$router.push({ path: '/signIn/settled/applicationRecord' })
        : this.$router.push({ path: '/signIn/settled/choice' })
    },
  },
}
</script>
<style scoped>
.seller-guide {
  background-color: #fff;
}
.seller-guid-item {
  position: relative;
  display: flex;
  padding: 96px 0;
}
.seller-guide .seller-guid-item {
  position: relative;
  display: flex;
  padding: 96px 0 0 0;
}
.adaptive-width {
  width: 1200px;
  margin: 0 auto;
}
.title {
  position: relative;
  z-index: 1;
  flex: 0 0 350px;
  font-size: 30px;
  white-space: nowrap;
  line-height: 40px;
  color: #192537;
  font-weight: 600;
  width: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.seller-policy-desc {
  margin-bottom: 80px;
}
.seller-flow-num {
  flex: 0 0 48px;
  height: 48px;
  background: #f2f5fa;
  font-size: 20px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  color: #8f9eb3;
}
.seller-flow-content {
  margin-left: 20px;
}
.seller-flow-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #192537;
  margin-bottom: 15px;
}
.seller-flow-desc {
  margin-top: 2px;
  font-size: 14px;
  color: rgba(25, 37, 55, 0.7);
}
.seller-notice {
  margin-bottom: 96px;
}
.bg img {
  /* height: 500px; */
}
.items {
  cursor: auto;
  position: relative;
  font-size: 0;
}
.item-img {
  width: 100%;
}
.bottom-wrap {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background: url('https://gogo2go-1253436512.cos.accelerate.myqcloud.com/Malaysia/takeout-admin/2023/06/d2b32aa725dd86515c32dc173f2b57bf.png') no-repeat;
  background-color: #fff;
  background-position: 50%;
  box-shadow: 0 -16px 32px 0 rgb(29 53 89 / 8%);
}
@media screen and (max-width: 997px) {
  .seller-guide .seller-guid-item {
    flex-wrap: wrap !important;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .adaptive-width {
    width: auto;
  }
  .bg img {
    display: none;
  }
  .page-title {
    font-size: 24px;
  }
  .title {
    font-size: 24px;
    flex: 0;
  }
  .mar-top-60 {
    margin-top: 50px;
  }
  .bottom-wrap-button {
    width: auto !important;
    margin-right: 0;
  }
  .seller-policy-title {
    font-size: 18px;
  }
  .bottom-wrap {
    justify-content: space-around;
  }
}
</style>
